import React from "react"
import Footer from "../components/footer/footer"
import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"
import "./rsvp.scss"

const rsvp = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <h2 className="page-title">Répondez S'il Vous Plaît</h2>
      <div className="form-container">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeeNOCOGzmUhsxqznGxy_aEgIMQ5Hi3omNyhDApKlootOfdTQ/viewform?embedded=true"
          width="640"
          height="1205"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
      <Footer />
    </div>
  )
}

export default rsvp
